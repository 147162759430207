<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <b-card v-if="Object.keys(details).length > 0">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <div>
          <h4 class="mb-0">
            {{ $t('Adsense incomes') }} (ID: {{ this.$route.params.id }})
          </h4>
        </div>
        <feather-icon
          icon="XIcon"
          size="18"
          class="cursor-pointer"
          @click="$router.go(-1)"
        />
      </div>
      <hr>
      <div
        class="alert alert-success"
      >
        <div class="alert-body">
          <div
            v-for="infoField in Object.keys(details)"
            :key="infoField"
          >
            <div
              v-if="metaInfoTextKeys[infoField]"
            >
              <div
                v-if="infoField === 'datetime_uploaded'"
              >
                {{ metaInfoTextKeys[infoField] }}: {{ getFormattedDate(new Date(details[infoField])) }}
              </div>
              <div v-else>
                {{ metaInfoTextKeys[infoField] }}: {{ details[infoField] }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-collapse
        accordion
        type="border"
        class="mt-2"
      >
        <app-collapse-item
          title="Original"
        >
          <b-table
            id="adsense_incomes"
            small
            striped
            :items="details.original"
            :fields="fields"
            responsive="sm"
          >
            <template #head(date)>
              {{ $t('adsense-incomes.date') }}
            </template>
            <template #head(impressions)>
              {{ $t('adsense-incomes.impressions') }}
            </template>
            <template #head(clicks)>
              {{ $t('adsense-incomes.clicks') }}
            </template>
            <template #head(revenue)>
              {{ $t('adsense-incomes.revenue') }}
            </template>
            <template #head(income)>
              {{ $t('adsense-incomes.income') }}
            </template>
            <template #head(site_income)>
              {{ $t('adsense-incomes.site_income') }}
            </template>
            <template #cell(check)="data">
              <feather-icon
                :class="data.item.ok === true ? 'checked' : 'unchecked'"
                :icon="data.item.ok === true ? 'CheckCircleIcon' : 'CircleIcon'"
                size="20"
              />
            </template>
            <template #cell(date)="data">
              {{ data.value }}
            </template>
            <template #cell(revenue)="data">
              {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}$
            </template>
            <template #cell(income)="data">
              {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}$
            </template>
            <template #cell(site_income)="data">
              {{ data.value && data.item.site_currency ? currency(data.item.site_currency, data.value) : currency('USD', data.value) }}
            </template>
            <template #cell(phoenix_revenue)="data">
              {{ calcPhoenixRevenue(data.item) }}
            </template>
            <template #cell(notes)="data">
              <feather-icon
                v-if="data.value !== ''"
                v-b-tooltip.hover.top="data.value"
                icon="InfoIcon"
                size="18"
                class="toggle-close cursor-pointer text-danger"
                @click="$router.go(-1)"
              />
            </template>
          </b-table>
        </app-collapse-item>
        <app-collapse-item
          title="Approve"
        >
          <b-table
            id="adsense_incomes"
            small
            striped
            :items="details.approve"
            :fields="fields"
            responsive="sm"
          >
            <template #head(date)>
              {{ $t('adsense-incomes.date') }}
            </template>
            <template #head(impressions)>
              {{ $t('adsense-incomes.impressions') }}
            </template>
            <template #head(clicks)>
              {{ $t('adsense-incomes.clicks') }}
            </template>
            <template #head(revenue)>
              {{ $t('adsense-incomes.revenue') }}
            </template>
            <template #head(income)>
              {{ $t('adsense-incomes.income') }}
            </template>
            <template #head(site_income)>
              {{ $t('adsense-incomes.site_income') }}
            </template>
            <template #cell(check)="data">
              <feather-icon
                :class="data.item.ok === true ? 'checked' : 'unchecked'"
                :icon="data.item.ok === true ? 'CheckCircleIcon' : 'CircleIcon'"
                size="20"
              />
            </template>
            <template #cell(date)="data">
              {{ data.value }}
            </template>
            <template #cell(revenue)="data">
              {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}$
            </template>
            <template #cell(income)="data">
              {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}$
            </template>
            <template #cell(site_income)="data">
              {{ data.value && data.item.site_currency ? currency(data.item.site_currency, data.value) : currency('USD', data.value) }}
            </template>
            <template #cell(phoenix_revenue)="data">
              {{ calcPhoenixRevenue(data.item) }}
            </template>
            <template #cell(notes)="data">
              <feather-icon
                v-if="data.value !== ''"
                v-b-tooltip.hover.top="data.value"
                icon="InfoIcon"
                size="18"
                class="toggle-close cursor-pointer text-danger"
                @click="$router.go(-1)"
              />
            </template>
          </b-table>
        </app-collapse-item>
      </app-collapse>
    </b-card>
  </div>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import getFormattedDate from '@/utils/date-formatter'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import { mapGetters } from 'vuex'
import currencyFormatter from '@/utils/currency-formatter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  BCard,
  BTable,
  VBTooltip,
} from 'bootstrap-vue'

export default {
  name: 'AdsenseIncomesDetails',
  components: {
    Breadcrumbs,
    BCard,
    AppCollapse,
    AppCollapseItem,
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      details: {},
      breadcrumbItems: [
        {
          text: 'adsense-incomes.title',
          to: '/adsense-incomes',
        },
        {
          text: this.$route.params.id,
          active: true,
        },
      ],
      metaInfoTextKeys: {
        report_job_id: 'Report job id',
        datetime_generated: 'Datetime generated',
        datetime_uploaded: 'Datetime uploaded',
        publisher_network_name: 'Publisher network name',
        adsense_user: 'Adsense user',
        adsense_name: 'Adsense name',
      },
      fields: [
        'check',
        { key: 'phoenix_site_domain', label: 'Phoenix site', class: 'domain-cell' },
        { key: 'date', label: 'Date', class: 'date-cell' },
        { key: 'clicks', label: 'Clicks' },
        { key: 'impressions', label: 'Impressions' },
        { key: 'revenue', label: 'Revenue' },
        { key: 'income', label: 'Income' },
        { key: 'site_income', label: 'Site income' },
        { key: 'phoenix_revenue', label: 'Phoenix revenue' },
        { key: 'notes', label: 'Notes', class: 'text-center' },
      ],
      getFormattedDate,
      currencies: [],
      currency: currencyFormatter,
    }
  },
  computed: {
    ...mapGetters('dashboard-incomes', [
      'getAdsenseIncomes',
    ]),
  },
  created() {
    this.getCurrencies()
    this.getDetails()
  },
  methods: {
    async getCurrencies() {
      const responseData = await useJwt.currencies()
      this.currencies = responseData.data.currencies || []
    },
    getDetails() {
      if (this.getAdsenseIncomes.length > 0) {
        const details = this.getAdsenseIncomes.find(item => item.report_job_id === this.$route.params.id)
        if (details) {
          this.details = details
        }
      } else {
        this.fetchAdsenseIncome()
      }
    },
    async fetchAdsenseIncome() {
      await useJwt.getAdsenseIncome(this.$route.params.id)
        .then(response => {
          this.details = response.data.adsenseincome
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data.errors[0].detail,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        })
    },
    calcPhoenixRevenue(item) {
      const { site_currency: siteCurrency, income, site_income: siteIncome } = item
      const currencyObj = this.currencies.find(c => c.name === siteCurrency)
      if (!currencyObj) {
        return this.currency('USD', income - siteIncome)
      }
      const siteIncomeUSD = siteCurrency !== 'USD' ? siteIncome / currencyObj.rate : siteIncome
      let phoenixRevenue = 0

      if (income !== 0 && income >= siteIncomeUSD) {
        phoenixRevenue = this.currency(siteCurrency, (income - siteIncomeUSD) * currencyObj.rate)
      }
      return phoenixRevenue
    },
  },
}
</script>
<style scoped>
  .alert-body {
    font-weight: 400;
  }
  .checked {
    color: #28c76f;
  }
</style>
<style>
  [dir] .table th.date-cell {
    min-width: 90px;
  }
  [dir] .table th.domain-cell {
    min-width: 220px;
  }
</style>
